import React, { useState, useContext } from "react";
import Flag from "react-world-flags";
import { GeneralContext } from "../../Contexts/GeneralContext";
import { CheckOutlined, CloseOutlined, FlagFilled, HomeFilled, DeleteFilled } from "@ant-design/icons"


import "./FlagSelect.css"

const FlagSelect = () => {

    const { general, handleSetGeneral, showFlags, flagsVisible } = useContext(GeneralContext);



    const handleChangeLanguage = (lang, flag) => {
        const lesLangues = {
            fr: "Français",
            en: "English",
            it: "Italian",
            ru: "Russian",
            "zh-Hans": "Chinese",
            es: "Spanish",
            et: "Estonian",
            de: "German",
            ko: "Korean",
            "pt-pt": "Portuguese",
        }

        handleSetGeneral({ ...general, langue: lang, flag: flag });
    }

    function handleChange(value, flag) {
        handleChangeLanguage(value, flag);
        showFlags();
        console.log("value :: ", value, flag)
    }

    return (
        <div className={`flag-select-content ${flagsVisible ? 'active' : null}`}>
            <ul>
                <li><a href="#" onClick={() => showFlags()}><CloseOutlined className="close" /></a></li>
                <li><a href="#" onClick={() => handleChange("fr", "fr")}><Flag className="flag" code={"fr"} />Français</a></li>
                <li><a href="#" onClick={() => handleChange("en", "sh")}><Flag className="flag" code={"sh"} />English</a></li>
                <li><a href="#" onClick={() => handleChange("it", "it")}><Flag className="flag" code={"it"} />Italian</a></li>
                <li><a href="#" onClick={() => handleChange("ru", "ru")}><Flag className="flag" code={"ru"} />Russian</a></li>
                <li><a href="#" onClick={() => handleChange("es", "es")}><Flag className="flag" code={"es"} />Spanish</a></li>
                <li><a href="#" onClick={() => handleChange("et", "ee")}><Flag className="flag" code={"ee"} />Estonian</a></li>
                <li><a href="#" onClick={() => handleChange("de", "de")}><Flag className="flag" code={"de"} />German</a></li>
                <li><a href="#" onClick={() => handleChange("ko", "kr")}><Flag className="flag" code={"kr"} />Korean</a></li>
                <li><a href="#" onClick={() => handleChange("zh-Hans", "cn")}><Flag className="flag" code={"cn"} />Chinese</a></li>
                <li><a href="#" onClick={() => handleChange("pt-pt", "pt")}><Flag className="flag" code={"pt"} />Portuguese</a></li>
            </ul>
        </div>
    );
};

export default FlagSelect;