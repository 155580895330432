import { gql } from "@apollo/client";

export const GET_ESTABLISHMENT = gql`
  query($id: ID!) {
    establishment(id: $id) {
        id
        Name
        Description
        Activate
        logo {
            url
        }
        address
        zipcode
        city
        Phone
        facebook
        twitter
        instagram
        cards {
            id
            name
            active
        }
        menus {
            id
            name
            active
        }
    }
  }
`;

