import React,{ useState, useEffect } from "react"

import "./Footer.css"

const Footer = () => {



    return (

            <div className="footer-container">

                <p>Copyright ©2021 Safe Menus - <span>Capucine Monaco</span></p>

            </div>

    )
}

export default Footer