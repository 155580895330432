import React, { useContext } from "react";
import { SERVER_URL } from "../../../constants";

const Wine = ({ wine }) => {

    const showWine = () => {
        if (wine.description) {
            if (wine.description !== "décrivez ce vin...") {
                return `"${wine.description}"`
            } else {
                return
            }
        }
    }

    return wine.active
        ? (
            <div className="wine-content">
                        
                <div className="gauche">
                    <p className="title" style={{ margin: "0 0 4px 0" }}>{wine.name} {wine.year && `| ${wine.year}`} {wine.organic && <span style={{
                        color: "#FFFFFF",
                        fontSize: "0.7rem",
                        lineHeight: "1rem",
                        verticalAlign:"top",
                        fontWeight: "700",
                        padding: "2px",
                        backgroundColor: "#5fd920"
                    }}>BIO</span>}</p>
                    {wine.description && <div className="tags">{showWine()}</div>}
                    {wine.designation && <div className="tags">Appellation : {wine.designation}</div>}
                    {wine.country && <div className="tags">Pays : {wine.country}</div>}
                    {wine.region && <div className="tags">Région : {wine.region}</div>}
                    {wine.producer && <div className="tags">Producteur : {wine.producer}</div>}
                    
                </div>

                <div className="droite">
                    <img src="https://api.prabel.pro/uploads/vins_Blue_fe7f25b777.jpg" />
                </div>
            </div>
        )
        : null
}

export default Wine