import React, { useContext, useState } from "react";
import { allergenes } from "./Allergenes";
import { SERVER_URL } from "../../../constants";
import { GeneralContext } from "../../../Contexts/GeneralContext";

const Dish = ({ dish }) => {

    const { general } = useContext(GeneralContext);
    const lang = general.langue ? general.langue : 'fr';

    const getIngredients = () => {
        let localIngredients = [];
        if (dish.Ingredients !== null && dish.Ingredients !== "") {
            Object.entries(JSON.parse(dish.Ingredients)).map((ing, i) => {
                if (ing[0] === lang) {
                    localIngredients.push(ing[1])
                }
            })
        }
        return localIngredients
    }

    const getAllergenes = () => {
        let titre;
        let children = [];
        const lesAllergenes = [];
        for (let key in Object.values(allergenes)) {
            lesAllergenes.push(Object.values(allergenes)[key]);
            lesAllergenes.forEach((el) => {
                if (Object.keys(el)[0] === lang) {
                    titre = Object.keys(Object.values(el)[0])[0];
                    const tousAllergenes = Object.values(Object.values(el)[0])[0];
                    children = [...tousAllergenes]
                }
            });
        }

        const dishChildren = dish.allergenes !== null
            ? dish.allergenes.split(',').map(d => children[d])
            : null

        const formatTitle = `${titre}: `;
        const formatChildren = dishChildren ? dishChildren.join(', ') : undefined
        return dish.allergenes !== null ? [formatTitle, formatChildren] : undefined
    }

    // LIGHTBOX //////////////////

    const [imageToShow, setImageToShow] = useState("");
    const [lightboxDisplay, setLightBoxDisplay] = useState(false);

    const showImage = (image) => {
        setImageToShow(image);
        setLightBoxDisplay(true);
    };

    const hideLightBox = () => {
        setLightBoxDisplay(false);
    };

    //////////////// return ////
    //////////////// return ////

    return dish.active
        ? (
            <>
            {
                lightboxDisplay ?
                    <div id="lightbox" onClick={hideLightBox}>
                        <img id="lightbox-img" src={imageToShow}></img>
                    </div>
                    : ""
            }
            <div className="dish-content">

                <div className="gauche">
               
                    <p className="title">{dish.name}</p>
                    <p className="tags">
                    {dish.description !== null
                        ? <span className="desc"> {`"${dish.description}"`}</span>
                        : null
                    }
                    </p>
                    <p className="tags">{getIngredients()}</p>
                    {dish.origine&& <p className="tags">origine: {dish.origine}</p>}
                    {dish.side_dish && <p className="tags">Accompagné par: {dish.side_dish}</p>}
                    <p className="tags">{getAllergenes()}</p>
                    
                </div>

                <div className="droite">
                    <div className="price">
                        {dish.price !== null
                            ? <span>{dish.price} €</span>
                            : <span>N/C</span>
                        }
                    </div>
                    {dish.img !== null
                        ? <img src={`https://api.prabel.pro${dish.img.url}`} onClick={() => showImage(`https://api.prabel.pro${dish.img.url}`)} />
                        : <img src="https://api.prabel.pro/img/noimage.jpg" />
                    }
                </div>

            </div>
            </>
        )
        : null
}

export default Dish