import React, { useContext, useEffect } from "react"
import { EstablishmentContext } from "../../Contexts/EstablishmentContext"
import { GeneralContext } from "../../Contexts/GeneralContext"
import { useLazyQuery } from "@apollo/client"

import { GET_ESTABLISHMENT } from "../../graphql/gql_Requests"

import { HashLink } from 'react-router-hash-link'

import Header from "../Header/Header"
import Footer from "../Footer/Footer"

import "./ButtonList.css"

import {
    useParams
} from "react-router-dom"

const ButtonList = () => {

    let { id } = useParams()

    const { general, handleSetGeneral } = useContext(GeneralContext)
    const { establishment, handleSetEstablishment } = useContext(EstablishmentContext)

    const [getEstablishment, { error: error_ge, data: data_ge }] = useLazyQuery(GET_ESTABLISHMENT, {
        onError: (error) => console.log("error :: ", JSON.stringify(error)),
        onCompleted: data_ge => handleSetEstablishment(data_ge.establishment)
    })

    useEffect(() => {
        getEstablishment({
            variables: { id: id }
        })
    }, [data_ge]);

    const defineCard = (cardID) => { 
        handleSetGeneral({ ...general, selectedCard: cardID})
    }

    const defineMenu = (menuID) => {
        handleSetGeneral({ ...general, selectedMenu: menuID })
    }

    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    let lesCartes = []

    if (establishment) {
        lesCartes = [...establishment.cards].sort(function (a, b) {
            return a.id - b.id;
        });
    }

    return establishment
    ? (
        <>
            <Header esta={establishment}/>

            <div className="button-list-content">

                    {
                    establishment.cards.length > 1
                        ? <h3>Nos Cartes</h3>
                        : <h3>Notre Carte</h3>
                    }

                    {
                    establishment.cards !== null
                            ? <ul>
                                {lesCartes.map((card, i) => (
                                    card.active 
                                        ? <li key={i}><HashLink to={`/cartes/${establishment.id}#${card.id}`} scroll={el => scrollWidthOffset(el)} onClick={() => defineCard(card.id)}>{card.name}</HashLink></li> 
                                        : null
                                ))}
                            </ul>
                        : <p>Pas encore de carte publiée</p>
                    }

                    {
                        establishment.menus.length > 1
                            ? <h3>Nos Formules</h3>
                            : <h3>Notre Formule</h3>
                    }

                    {
                        establishment.menus !== null
                            ? <ul>
                                {establishment.menus.map((menu, i) => (
                                    menu.active ? <li key={i}><HashLink to={`/menus/${establishment.id}#${menu.id}`} scroll={el => scrollWidthOffset(el)} onClick={() => defineMenu(menu.id)}>{menu.name}</HashLink></li> : null
                                ))}
                            </ul>
                            : <p>Pas encore de formule publiée</p>
                    }

                <Footer />

            </div>
        </>
    )
    : null
}

export default ButtonList