import React from "react"
import ReactDOM from "react-dom"

import { EstablishmentProvider } from "./Contexts/EstablishmentContext"
import { GeneralProvider } from "./Contexts/GeneralContext"

import { BrowserRouter as Router } from "react-router-dom";

import App from "./App"

import client from "./graphql/client"

import { ApolloProvider } from "@apollo/client"

ReactDOM.render(
    <ApolloProvider client={client}>
        <Router>
            <GeneralProvider>
                <EstablishmentProvider>
                    <App />
                </EstablishmentProvider>
            </GeneralProvider>
        </Router>
    </ApolloProvider>,
    document.getElementById("root")
)