import React, { useState, useContext, useEffect, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import { GeneralContext } from "../../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../../Contexts/EstablishmentContext";

import { GET_CATEGORIES } from "./gql_Cards";
import { GET_DRINK_TYPES } from "./gql_Cards";
import { GET_WINE_TYPES } from "./gql_Cards";

import Dish from "./Dish";
import Drink from "./Drink";
import Wine from "./Wine";

const Carte = ({ esta, card }) => {

    const { general, handleSetGeneral } = useContext(GeneralContext);
    const { establishment } = useContext(EstablishmentContext);

    const lang = general.langue ? general.langue : 'fr';

    const [title, setTitle] = useState('La Carte')
    const [subTitle, setSubTitle] = useState('de votre restaurant')
    const [categories, setCategories] = useState([])
    // const [visible, setVisible] = useState(false)
    const [visibleDrink, setVisibleDrink] = useState(false)
    const [visibleWine, setVisibleWine] = useState(false)
    const [allEstaDishes, setAllEstaDishes] = useState([])
    const [allEstaDrinks, setAllEstaDrinks] = useState([])
    const [allEstaWines, setAllEstaWines] = useState([])
    const [cardContent, setCardContent] = useState(undefined)
    const [drinkContent, setDrinkContent] = useState(undefined)
    const [wineContent, setWineContent] = useState(undefined)
    const [drinkTypes, setDrinkTypes] = useState([])
    const [wineTypes, setWineTypes] = useState([])

    const [getCategories] = useLazyQuery(GET_CATEGORIES, {
        onError: error_gc => console.log("error_gc", JSON.stringify(error_gc)),
        onCompleted: data_gc => createAllEstaDishes(data_gc),
        fetchPolicy: "no-cache",
    });

    const [getDrinkTypes] = useLazyQuery(GET_DRINK_TYPES, {
        onError: error_gdt => console.log("error_gdt", JSON.stringify(error_gdt)),
        onCompleted: data_gdt => createAllEstaDrinks(data_gdt),
        fetchPolicy: "no-cache",
    });

    const [getWineTypes] = useLazyQuery(GET_WINE_TYPES, {
        onError: error_gdt => console.log("error_gdt", JSON.stringify(error_gdt)),
        onCompleted: data_gdt => createAllEstaWines(data_gdt),
        fetchPolicy: "no-cache",
    });

    const handleRefresh = useCallback((datas) => {
        if (datas) {
            defineCard(datas)
            defineDrinks(datas)
            defineWines(datas)
            getCategories({ variables: { establishment: establishment.id } });
            getDrinkTypes({ variables: { establishments: establishment.id } });
            getWineTypes({ variables: { establishments: establishment.id } });
        }
    }, [])

    //////////////// tous les plats ////
    //////////////// tous les plats ////
    //////////////// tous les plats ////

    const getPaneName = (panName) => {
        const lang = general.langue ? general.langue : 'fr';
        const tempName = Object.entries(JSON.parse(panName)).find((n, i) => {
            return lang === n[0]
        })
        return tempName[1]
    }

    const createAllEstaDishes = (datas) => {
        const newPanes = datas.categories.map((pane) => {
            return {
                rank: JSON.parse(pane.rank),
                title: getPaneName(pane.name),
                content: pane.dishes.map((dish,i) =>
                    <DishInList
                        categories={categories}
                        cat={pane.id}
                        cats={datas.categories}
                        dish={dish}
                        key={i}
                    />),
                key: pane.id,
            }
        })
        newPanes.sort((a, b) => a.rank - b.rank)
        setAllEstaDishes([...newPanes])
    }

    const DishInList = ({ dish }) => {
        const alreadyOnThisCards = dish.cards.map(card => card.id)
        const check = alreadyOnThisCards.indexOf(cardContent.id) !== -1 ? true : false
        return dish.active
            ? (
                <button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={check}
                >
                    <span style={{ fontWeight: "500" }}>{dish.name}</span>
                </button>
            )
            : (
                <button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={true}
                >
                    {dish.name}
                </button>
            )
    }

    const viewAllEstaDishes = () => {
        return allEstaDishes.length > 0
            ? (
                allEstaDishes.map((cat,i) => {

                    return (
                        <div key={i}>
                            <h3>{cat.title}</h3>
                            {cat.content.map(d => d)}
                        </div>
                    )
                })
            )
            : null
    }

    const createAllEstaDrinks = (datas) => {

        const newPanes = datas.drinkTypes.map((pane) => {
            return {
                title: pane.name,
                content: pane.drinks.map((drink,i) =>
                    <DrinkInList
                        drinkTypes={drinkTypes}
                        types={datas.drinkTypes}
                        drink={drink}
                        type={pane.id}
                        key={i}
                    />),
                id: JSON.parse(pane.id),
            }
        })
        newPanes.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        setAllEstaDrinks([...newPanes])
    }

    const DrinkInList = ({ drink }) => {

        const alreadyOnThisCards = drink.cards.map(card => card.id)
        const check = alreadyOnThisCards.indexOf(cardContent.id) !== -1 ? true : false
        return drink.active
            ? (
                <button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={check}
                >
                    <span style={{ fontWeight: "500" }}>{drink.name}</span>
                </button>
            )
            : (
                <button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={true}
                >
                    {drink.name}
                </button>
            )
    }

    const handleDeleteDrink = (e) => {
        const alreadyInCard = drinkContent.drinks.map(d => d.id)
        const index = alreadyInCard.indexOf(e)
        alreadyInCard.splice(index, 1);
        const zavars = {
            id: drinkContent.id,
            drinks: [...alreadyInCard],
        }

        if (drinkTypes.length < 2) {
            setDrinkTypes([])
        }

    }

    //////////////////////////////////////////////// toutes les boissons ////
    //////////////////////////////////////////////// toutes les boissons ////
    //////////////////////////////////////////////// toutes les boissons ////



    //////////////// tous les vins ////
    //////////////// tous les vins ////
    //////////////// tous les vins ////

    const createAllEstaWines = (datas) => {

        const newPanes = datas.wineTypes.map((pane) => {

            return {
                title: pane.name,
                content: pane.wines.map((wine,i) =>
                    <WineInList
                        wineTypes={wineTypes}
                        types={datas.wineTypes}
                        wine={wine}
                        type={pane.id}
                        key={i}
                    />),
                id: JSON.parse(pane.id),
            }
        })
        newPanes.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        setAllEstaWines([...newPanes])
    }

    const WineInList = ({ wine }) => {

        const alreadyOnThisCards = wine.cards.map(card => card.id)
        const check = alreadyOnThisCards.indexOf(cardContent.id) !== -1 ? true : false
        return wine.active
            ? (
                <button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={check}
                >
                    <span style={{ fontWeight: "500" }}>{wine.name}</span>
                </button>
            )
            : (
                <button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={true}
                >
                    {wine.name}
                </button>
            )
    }

    const handleDeleteWine = (e) => {
        const alreadyInCard = wineContent.wines.map(w => w.id)
        const index = alreadyInCard.indexOf(e)
        alreadyInCard.splice(index, 1);
        const zavars = {
            id: wineContent.id,
            wines: [...alreadyInCard],
        }

        if (wineTypes.length < 2) {
            setWineTypes([])
        }

        // updateCard({ variables: zavars })
    }

    //////////////////////////////////////////////// tous les vins ////
    //////////////////////////////////////////////// tous les vins ////
    //////////////////////////////////////////////// tous les vins ////

    useEffect(() => {

        if (card) {
            defineCard(card)
            defineDrinks(card)
            defineWines(card)
            handleSetGeneral({
                ...general,
                localCardContent: JSON.stringify(card),
                page: "/cartedetails",
            })
        } else {
            const localCardContent = general.localCardContent
            if (localCardContent !== undefined) {
                defineCard(JSON.parse(localCardContent))
                defineDrinks(JSON.parse(localCardContent))
                defineWines(JSON.parse(localCardContent))
                handleSetGeneral({
                    ...general,
                    page: "/cartedetails",
                })
            }
        }
        
        if (establishment) {
            getCategories({ variables: { establishment: establishment.id } });
            getDrinkTypes({ variables: { establishments: establishment.id } });
            getWineTypes({ variables: { establishments: establishment.id } });
        }

    }, []);

    useEffect(() => {
        if (cardContent !== undefined) {
            defineCard(cardContent)
        }
    }, [lang]);

    const defineCard = (vars) => {
        setTitle(vars.name)
        setSubTitle(vars.description)
        let newCategories = [];
        let tempCategories = [];
        let temp2Categories = [];
        vars.dishes.map(cat => {
            if (cat.active) {
                const catRaw = cat.categories[0].name
                const catRank = cat.categories[0].rank
                const catTrad = Object.entries(JSON.parse(catRaw))
                    .find(cat => {
                        return (cat[0] === lang)
                            ? tempCategories.push(JSON.stringify([cat[1], catRank]))
                            : undefined
                    })
                tempCategories.map(cat => {
                    return temp2Categories.indexOf(cat) === -1
                        ? temp2Categories.push(cat)
                        : null
                })
                newCategories = temp2Categories.map(cat => JSON.parse(cat))
                newCategories.sort((a, b) => a[1] - b[1])
                setCategories(newCategories)
            }
        })

        setCardContent(vars)
    }

    const defineDrinks = (vars) => {
        let tempBoissons = []
        let uniq = []
        vars.drinks.map((boisson, i) => {
            if (boisson.active) {
                const boissonType = boisson.drink_types[0].name
                tempBoissons.push(boissonType)
                uniq = [...new Set(tempBoissons)];
                if (i + 1 === vars.drinks.length) {
                    uniq.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
                    setDrinkTypes(uniq)
                }
            }
        })
        setDrinkContent(vars)
    }

    const defineWines = (vars) => {
        let tempVins = []
        let uniq = []
        vars.wines.map((vin, i) => {
            if (vin.active) {
                const vinType = vin.wine_types[0].name
                tempVins.push(vinType)
                uniq = [...new Set(tempVins)];
                if (i + 1 === vars.wines.length) {
                    uniq.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
                    setWineTypes(uniq)
                }
            }
        })
        setWineContent(vars)
    }

    const getCardContent = () => {
        if (categories.length > 0) {
            return (
                categories.map((cat,i) => {
                    return (
                        <div className="plats" key={i}>
                            <h3>{cat[0]}</h3>
                            {cardContent.dishes.map((dish,i) => {
                                const tempCat = Object.entries(JSON.parse(dish.categories[0].name))
                                const tempCat2 = tempCat.filter(tc => lang === tc[0] ? tc : null)
                                const transCat = tempCat2.map(tc => tc[1])
                                return transCat[0] === cat[0]
                                    ? <Dish key={i} dish={dish} />
                                    : null
                            })}
                        </div>
                    )
                })
            )
        }
    }

    const getDrinkContent = () => {
        if (drinkTypes.length > 0) {
            return (
                drinkTypes.map((dt,i) => {
                    return (
                        <div className="boissons" key={i}>
                            <h3>{dt}</h3>
                            {drinkContent.drinks.map((drink,i) => {
                                return drink.drink_types[0].name === dt
                                    ? <Drink key={i} drink={drink} />
                                    : null
                            })}
                        </div>
                    )
                })
            )
        }
    }

    const getWineContent = () => {
        if (wineTypes.length > 0) {
            return (
                wineTypes.map((dt,i) => {
                    return (
                        <div className="vins" key={i}>
                            <h3>{dt}</h3>
                            {wineContent.wines.map((wine,i) => {
                                return wine.wine_types[0].name === dt
                                    ? <Wine key={i} wine={wine} />
                                    : null
                            })}
                        </div>
                    )
                })
            )
        }
    }

    //////////////////////////////////// return ////////
    //////////////////////////////////// return ////////

    return (
        <div id={`${card.id}`} className="carte-list-content">

            <h3>{card.name}</h3>
            <h4>{card.description || esta.Name}</h4>

            {getCardContent()}
            {getDrinkContent()}
            {getWineContent()}

        </div>

    );
};

export default Carte;
