import React, { createContext, useState } from "react";

const exist = () => {
    const localEstablishment = JSON.parse(localStorage.getItem('establishment'))
    if (localEstablishment !== null) {
        return localEstablishment
    } else {
        return undefined
    }
}

export const EstablishmentContext = createContext({
    establishment: {},
    setEstablishment: () => { },
});

export const EstablishmentProvider = ({ children }) => {

    const [establishment, setEstablishment] = useState(exist());

    const handleSetEstablishment = (establishment) => {
        setEstablishment(establishment);
        localStorage.removeItem('establishment');
        localStorage.setItem('establishment', JSON.stringify(establishment));
    }

    return (
        <EstablishmentContext.Provider value={{ establishment, handleSetEstablishment }}>
            {children}
        </EstablishmentContext.Provider>
    )
}