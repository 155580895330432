import React, { useContext } from "react";
import { SERVER_URL } from "../../../constants";


const Drink = ({ drink }) => {

    console.log("drink :: ", drink)

    return drink.active
        ? (
                  
            <div className="dish-content">

                <div className="gauche">
                    <p className="title">{drink.name}</p>
                    {
                        drink.description !== "" 
                        ? <p className="tags"> {drink.description !== null ? `"${drink.description}"` : null}</p> 
                        : null
                    }
                        {drink.specs.map((sp, i) => {
                            return (
                                <div key={i} className="specs-loop">
                                    <p className="tags">{sp.designation}: {sp.size ? `${sp.size} cl` : undefined}</p>
                                    <p className="tags">Prix: {sp.price ? `${sp.price} €` : 'non précisé'}</p>
                                    {sp.priceHH && <p className="tags">Prix Happy Hour: {`${sp.priceHH} €`}</p>}
                                </div>
                            )
                        })}
                </div>

                <div className="droite">
                    {drink.price !== null
                        ? <span className="price"> {drink.specs[0].price} €</span>
                        : <span className="price"> n/c</span>
                    }
                    <img src="https://api.prabel.pro/uploads/soft_Drinks_Blue_1e66ccfdba.jpg" />
                </div>

            </div>
        )
        : null
}

export default Drink