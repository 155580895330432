import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GeneralContext } from "../../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../../Contexts/EstablishmentContext";

import { GET_CATEGORIES } from "./gql_Menus";
import { GET_DRINK_TYPES } from "./gql_Menus";
import { GET_WINE_TYPES } from "./gql_Menus";

import Dish from "./Dish";
import Drink from "./Drink";
import Wine from "./Wine";

const Menu = ({ esta, menu }) => {



    const { general, handleSetGeneral } = useContext(GeneralContext);
    const { establishment } = useContext(EstablishmentContext);

    const lang = general.langue ? general.langue : 'fr';


    const [title, setTitle] = useState('La Carte')
    const [subTitle, setSubTitle] = useState('de votre restaurant')
    const [categories, setCategories] = useState([])
    const [visible, setVisible] = useState(false)
    const [visibleDrink, setVisibleDrink] = useState(false)
    const [visibleWine, setVisibleWine] = useState(false)
    const [allEstaDishes, setAllEstaDishes] = useState([])
    const [allEstaDrinks, setAllEstaDrinks] = useState([])
    const [allEstaWines, setAllEstaWines] = useState([])
    const [menuContent, setMenuContent] = useState(undefined)
    const [drinkContent, setDrinkContent] = useState(undefined)
    const [wineContent, setWineContent] = useState(undefined)
    const [drinkTypes, setDrinkTypes] = useState([])
    const [wineTypes, setWineTypes] = useState([])

    const [getCategories] = useLazyQuery(GET_CATEGORIES, {
        onError: error_gc => console.log("error_gc", JSON.stringify(error_gc)),
        onCompleted: data_gc => createAllEstaDishes(data_gc),
        fetchPolicy: "no-cache",
    });

    const [getDrinkTypes] = useLazyQuery(GET_DRINK_TYPES, {
        onError: error_gdt => console.log("error_gdt", JSON.stringify(error_gdt)),
        onCompleted: data_gdt => createAllEstaDrinks(data_gdt),
        fetchPolicy: "no-cache",
    });

    const [getWineTypes] = useLazyQuery(GET_WINE_TYPES, {
        onError: error_gdt => console.log("error_gdt", JSON.stringify(error_gdt)),
        onCompleted: data_gdt => createAllEstaWines(data_gdt),
        fetchPolicy: "no-cache",
    });



    const handleRefresh = useCallback((datas) => {
        if (datas) {
            defineMenu(datas)
            defineDrinks(datas)
            defineWines(datas)
            getCategories({ variables: { establishment: establishment.id } });
            getDrinkTypes({ variables: { establishments: establishment.id } });
            getWineTypes({ variables: { establishments: establishment.id } });
        }
    }, [])

    //////////////// tous les plats ////
    //////////////// tous les plats ////
    //////////////// tous les plats ////

    const getPaneName = (panName) => {
        const lang = general.langue ? general.langue : 'fr';
        const tempName = Object.entries(JSON.parse(panName)).find((n, i) => {
            return lang === n[0]
        })
        return tempName[1]
    }

    const createAllEstaDishes = (datas) => {
        const newPanes = datas.categories.map((pane) => {
            return {
                rank: JSON.parse(pane.rank),
                title: getPaneName(pane.name),
                content: pane.dishes.map(dish =>
                    <DishInList
                        categories={categories}
                        cat={pane.id}
                        cats={datas.categories}
                        dish={dish}
                    />),
                key: pane.id,
            }
        })
        newPanes.sort((a, b) => a.rank - b.rank)
        setAllEstaDishes([...newPanes])
    }

    const DishInList = ({ dish }) => {
        const alreadyOnThisCards = dish.menus.map(menu => menu.id)
        const check = alreadyOnThisCards.indexOf(menuContent.id) !== -1 ? true : false
        return dish.active
            ? (
                <button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={check}
                // onClick={() => handleAddDishToMenu(dish.id)}
                >
                    <span style={{ fontWeight: "500" }}>{dish.name}</span>
                </button>
            )
            : (
                <button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={true}
                >
                    {dish.name}
                </button>
            )
    }

    // const handleAddDishToCard = (id) => {
    //     const alreadyInCard = cardContent.dishes.map(d => d.id)
    //     const zavars = {
    //         id: cardContent.id,
    //         dishes: [...alreadyInCard, id],
    //     }
    //     // updateCard({ variables: zavars })
    // }

    // const handleOnClose = () => {
    //     setVisible(false)
    // }

    const viewAllEstaDishes = () => {
        return allEstaDishes.length > 0
            ? (
                allEstaDishes.map(cat => {

                    return (
                        <div>
                            <h3>{cat.title}</h3>
                            {cat.content.map(d => d)}
                        </div>
                    )
                })
            )
            : null
    }

    // const handleDeleteDish = (e) => {
    //     const alreadyInCard = cardContent.dishes.map(d => d.id)
    //     const index = alreadyInCard.indexOf(e)
    //     alreadyInCard.splice(index, 1);
    //     const zavars = {
    //         id: cardContent.id,
    //         dishes: [...alreadyInCard],
    //     }

    //     if (categories.length < 2) {
    //         setCategories([])
    //     }

    //     // updateCard({ variables: zavars })
    // }

    //////////////////////////////////////////////// tous les plats ////
    //////////////////////////////////////////////// tous les plats ////
    //////////////////////////////////////////////// tous les plats ////



    //////////////// toutes les boissons ////
    //////////////// toutes les boissons ////
    //////////////// toutes les boissons ////

    const createAllEstaDrinks = (datas) => {

        const newPanes = datas.drinkTypes.map((pane) => {
            // console.log("pane :: ", pane)
            return {
                title: pane.name,
                content: pane.drinks.map(drink =>
                    <DrinkInList
                        drinkTypes={drinkTypes}
                        types={datas.drinkTypes}
                        drink={drink}
                        type={pane.id}
                    />),
                id: JSON.parse(pane.id),
            }
        })
        newPanes.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        // console.log("newPanes :: ", newPanes)
        setAllEstaDrinks([...newPanes])
    }

    const DrinkInList = ({ drink }) => {

        const alreadyOnThisMenus = drink.menus.map(menu => menu.id)
        const check = alreadyOnThisMenus.indexOf(menuContent.id) !== -1 ? true : false
        return drink.active
            ? (
                <button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={check}
                // onClick={() => handleAddDrinkToMenu(drink.id)}
                >
                    <span style={{ fontWeight: "500" }}>{drink.name}</span>
                </button>
            )
            : (
                <button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={true}
                >
                    {drink.name}
                </button>
            )
    }

    // const handleAddDrinkToCard = (id) => {
    //     const alreadyInCard = drinkContent.drinks.map(d => d.id)
    //     const zavars = {
    //         id: drinkContent.id,
    //         drinks: [...alreadyInCard, id],
    //     }
    //     updateCard({ variables: zavars })
    // }

    // const handleOnCloseDrink = () => {
    //     setVisibleDrink(false)
    // }

    // const viewAllEstaDrinks = () => {
    //     return allEstaDrinks.length > 0
    //         ? (
    //             allEstaDrinks.map(ed => {
    //                 return (
    //                     <div>
    //                         <h3>{ed.title}</h3>
    //                         {ed.content.map(d => d)}
    //                     </div>
    //                 )
    //             })
    //         )
    //         : null
    // }

    const handleDeleteDrink = (e) => {
        const alreadyInCard = drinkContent.drinks.map(d => d.id)
        const index = alreadyInCard.indexOf(e)
        alreadyInCard.splice(index, 1);
        const zavars = {
            id: drinkContent.id,
            drinks: [...alreadyInCard],
        }

        if (drinkTypes.length < 2) {
            setDrinkTypes([])
        }

        // updateCard({ variables: zavars })
    }

    //////////////////////////////////////////////// toutes les boissons ////
    //////////////////////////////////////////////// toutes les boissons ////
    //////////////////////////////////////////////// toutes les boissons ////



    //////////////// tous les vins ////
    //////////////// tous les vins ////
    //////////////// tous les vins ////

    const createAllEstaWines = (datas) => {

        const newPanes = datas.wineTypes.map((pane) => {

            return {
                title: pane.name,
                content: pane.wines.map(wine =>
                    <WineInList
                        wineTypes={wineTypes}
                        types={datas.wineTypes}
                        wine={wine}
                        type={pane.id}
                    />),
                id: JSON.parse(pane.id),
            }
        })
        newPanes.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        // console.log("newPanes :: ", newPanes)
        setAllEstaWines([...newPanes])
    }

    const WineInList = ({ wine }) => {

        const alreadyOnThisMenus = wine.menus.map(menu => menu.id)
        const check = alreadyOnThisMenus.indexOf(menuContent.id) !== -1 ? true : false
        return wine.active
            ? (
                <button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={check}
                // onClick={() => handleAddWineToMenu(wine.id)}
                >
                    <span style={{ fontWeight: "500" }}>{wine.name}</span>
                </button>
            )
            : (
                <button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={true}
                >
                    {wine.name}
                </button>
            )
    }

    // const handleAddWineToCard = (id) => {
    //     const alreadyInCard = wineContent.wines.map(d => d.id)
    //     const zavars = {
    //         id: wineContent.id,
    //         wines: [...alreadyInCard, id],
    //     }
    //     updateCard({ variables: zavars })
    // }

    // const handleOnCloseWine = () => {
    //     setVisibleWine(false)
    // }

    // const viewAllEstaWines = () => {
    //     return allEstaWines.length > 0
    //         ? (
    //             allEstaWines.map(ew => {
    //                 return (
    //                     <div>
    //                         <h3>{ew.title}</h3>
    //                         {ew.content.map(d => d)}
    //                     </div>
    //                 )
    //             })
    //         )
    //         : null
    // }

    const handleDeleteWine = (e) => {
        const alreadyInCard = wineContent.wines.map(w => w.id)
        const index = alreadyInCard.indexOf(e)
        alreadyInCard.splice(index, 1);
        const zavars = {
            id: wineContent.id,
            wines: [...alreadyInCard],
        }

        if (wineTypes.length < 2) {
            setWineTypes([])
        }

        // updateCard({ variables: zavars })
    }

    //////////////////////////////////////////////// tous les vins ////
    //////////////////////////////////////////////// tous les vins ////
    //////////////////////////////////////////////// tous les vins ////

    // const handleBackToCards = () => {
    //     history.push({ pathname: "/cartes", card: location.card })
    //     console.log("general :: ", general)
    // }

    useEffect(() => {

        if (menu) {
            defineMenu(menu)
            defineDrinks(menu)
            defineWines(menu)
            handleSetGeneral({
                ...general,
                localMenuContent: JSON.stringify(menu),
            })
        } else {
            const localMenuContent = general.localMenuContent
            if (localMenuContent !== undefined) {
                defineMenu(JSON.parse(localMenuContent))
                defineDrinks(JSON.parse(localMenuContent))
                defineWines(JSON.parse(localMenuContent))
                handleSetGeneral({
                    ...general,
                })
            }
        }
        // if (location.establishment) {
        //     // setEsta(`- ${location.establishment.Name} -`)
        // }
        
        if (establishment) {
            // console.log(":: UE ::")
            getCategories({ variables: { establishment: establishment.id } });
            getDrinkTypes({ variables: { establishments: establishment.id } });
            getWineTypes({ variables: { establishments: establishment.id } });
        }

    }, []);

    useEffect(() => {
        if (menuContent !== undefined) {
            defineMenu(menuContent)
        }
    }, [lang]);

    const defineMenu = (vars) => {
        setTitle(vars.name)
        setSubTitle(vars.description)
        // setPrice(vars.price)
        let newCategories = [];
        let tempCategories = [];
        let temp2Categories = [];
        vars.dishes.map(cat => {
            if (cat.active) {
                const catRaw = cat.categories[0].name
                const catRank = cat.categories[0].rank
                const catTrad = Object.entries(JSON.parse(catRaw))
                    .find(cat => {
                        return (cat[0] === lang)
                            ? tempCategories.push(JSON.stringify([cat[1], catRank]))
                            : undefined
                    })
                tempCategories.map(cat => {
                    return temp2Categories.indexOf(cat) === -1
                        ? temp2Categories.push(cat)
                        : null
                })
                newCategories = temp2Categories.map(cat => JSON.parse(cat))
                newCategories.sort((a, b) => a[1] - b[1])
                setCategories(newCategories)
            }
        })

        setMenuContent(vars)
    }

    const defineDrinks = (vars) => {
        let tempBoissons = []
        let uniq = []
        vars.drinks.map((boisson, i) => {
            if (boisson.active) {
                const boissonType = boisson.drink_types[0].name
                tempBoissons.push(boissonType)
                uniq = [...new Set(tempBoissons)];
                if (i + 1 === vars.drinks.length) {
                    uniq.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
                    setDrinkTypes(uniq)
                }
            }
        })
        setDrinkContent(vars)
    }

    const defineWines = (vars) => {
        let tempVins = []
        let uniq = []
        vars.wines.map((vin, i) => {
            if (vin.active) {
                const vinType = vin.wine_types[0].name
                tempVins.push(vinType)
                uniq = [...new Set(tempVins)];
                if (i + 1 === vars.wines.length) {
                    uniq.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
                    setWineTypes(uniq)
                }
            }
        })
        setWineContent(vars)
    }

    const getMenuContent = () => {
        if (categories.length > 0) {
            return (
                categories.map(cat => {
                    return (
                        <div className="plats">
                            <h3>{cat[0]}</h3>
                            {menuContent.dishes.map(dish => {
                                const tempCat = Object.entries(JSON.parse(dish.categories[0].name))
                                const tempCat2 = tempCat.filter(tc => lang === tc[0] ? tc : null)
                                const transCat = tempCat2.map(tc => tc[1])
                                return transCat[0] === cat[0]
                                    ? <Dish dish={dish} />
                                    : null
                            })}
                        </div>
                    )
                })
            )
        }
    }

    const getDrinkContent = () => {
        if (drinkTypes.length > 0) {
            return (
                drinkTypes.map(dt => {
                    return (
                        <div className="boissons">
                            <h3>{dt}</h3>
                            {drinkContent.drinks.map(drink => {
                                return drink.drink_types[0].name === dt
                                    ? <Drink drink={drink} />
                                    : null
                            })}
                        </div>
                    )
                })
            )
        }
    }

    const getWineContent = () => {
        if (wineTypes.length > 0) {
            return (
                wineTypes.map(dt => {
                    return (
                        <div className="vins">
                            <h3>{dt}</h3>
                            {wineContent.wines.map(wine => {
                                return wine.wine_types[0].name === dt
                                    ? <Wine wine={wine} />
                                    : null
                            })}
                        </div>
                    )
                })
            )
        }
    }

    //////////////////////////////////// return ////////
    //////////////////////////////////// return ////////

    return (
        <div id={`${menu.id}`} className="menu-list-content">

            <h3>{menu.name}</h3>
            <h4>{menu.description || esta.Name}</h4>
            <h4><span>{menu.price} €</span></h4>

            {getMenuContent()}
            {getDrinkContent()}
            {getWineContent()}

        </div>

    );
};

export default Menu;
