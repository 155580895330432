import React, { useState, useEffect } from "react"

import "./LogoResto.css"
import noimage from "./visuels/noimage.jpg"

const LogoResto = () => {
    return (
        <div>
            <div className="visuel-resto"></div>
        </div>
    )
}

export default LogoResto