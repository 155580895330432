import React, { createContext, useState } from "react";

const exist = () => {
    const localGeneral = JSON.parse(localStorage.getItem('general'))
    if (localGeneral !== null) {
        return localGeneral
    } else {
        return {}
    }
}

export const GeneralContext = createContext({
    general: {},
    setGeneral: () => { },
});

export const GeneralProvider = ({ children }) => {

    const [general, setGeneral] = useState(exist());

    const handleSetGeneral = (general) => {
        setGeneral(general);
        localStorage.removeItem('general');
        localStorage.setItem('general', JSON.stringify(general));
        // console.log("general :: ", general)
    }

    const [flagsVisible, setFlagsvisible] = useState(false)

    const showFlags = () => setFlagsvisible(!flagsVisible)

    return (
        <GeneralContext.Provider value={{ general, handleSetGeneral, showFlags, flagsVisible }}>
            {children}
        </GeneralContext.Provider>
    )
}