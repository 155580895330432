import React, { useState, useContext, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GeneralContext } from "../../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../../Contexts/EstablishmentContext";
import { GET_CARDS } from "./gql_Cards";

import "./Content.css"

import Carte from "./Carte"


const Content = () => {

    const [esta, setEsta] = useState('')

    const { general, handleSetGeneral } = useContext(GeneralContext);
    const { establishment } = useContext(EstablishmentContext);

    const [cards, { error: error_gc, data: data_gc }] = useLazyQuery(GET_CARDS, {
        onError: (error_gc) => console.log("error_gc", JSON.stringify(error_gc)),
        onCompleted: (data_gc) => listCards(data_gc),
        fetchPolicy: "no-cache",
    });

    const [allCards, setAllcards] = useState([])
    const [plural, setPlural] = useState(false);

    useEffect(() => {

        if (establishment) {
            cards({ variables: { establishments: establishment.id } });
        }

    }, []);

    const listCards = (datas) => {
        if (datas.cards.length > 1) {
            setPlural(true);
        }
        const newKidsOnTheBlock = datas.cards.map(c => c)
        newKidsOnTheBlock.sort((a, b) => a.id - b.id)
        setAllcards(newKidsOnTheBlock)
    }

    if (!establishment) {
        return <p>Erreur... nous ne pouvons pas afficher de carte</p>;
    }

    return (

        <div className="content">
        {
            allCards.length > 0
                ? allCards.map((card,i) => <Carte
                    esta={establishment}
                    card={card}
                    listCards={listCards}
                    key={i}
                />)
                : null
        }
        </div>
        
    )
}

export default Content