import React, { useState, useEffect } from "react"

import logo from "../../logoCouleurs.png"
import "./Splash.css"


const Spalsh = () => {
    return (
        <div className="splash-content">
            <img src={logo} alt="Safe Menus"/>
        </div>
    )
}

export default Spalsh