import { gql } from "@apollo/client";

export const GET_CARDS = gql`
    query cards($establishments: [ID!]) {
        cards(where: { establishments: $establishments }) {
            id
            establishments{
                id
            }
            name
            description
            active
            dishes {
                id
                name
                active
                img {
                    formats
                    url
                }
                price
                allergenes
                origine
                side_dish
                description
                Ingredients
                rank
                categories {
                    id
                    name
                    rank
                }
            }
            drinks {
                id
                name
                specs {
                    id
                    size
                    price
                    priceHH
                    designation
                }
                description
                active
                drink_types {
                    id
                    name
                }
            }
            wines {
                id
                name
                country
                year
                region
                designation
                producer
                description
                organic
                specs {
                    id
                    size
                    price
                    priceHH
                    designation
                }
                active
                wine_types {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_CATEGORIES = gql`
query categories($establishment: ID) {
  categories(where: { establishment: $establishment }) {
    id
    rank
    name
    description
    dishes (sort: "id:asc") {
        id
        name
        active
        folded
        rank
        img {
            id
            created_at
            updated_at
            name
            alternativeText
            caption
            width
            height
            formats
            size
            url
            previewUrl
        }
        price
        allergenes
        origine
        side_dish
        description
        Ingredients
        cards {
                id
            }
        menus {
                id
            }
        }
    establishment {
        id
        Name
        }  
    }
}
`;

export const GET_DRINK_TYPES = gql`
    query drinkTypes($establishments: ID) {
        drinkTypes(where: { establishments: $establishments }) {
            id
            name
            drinks {
                id
                active
                name
                description
                cards {
                    id
                }
                menus {
                    id
                }
                specs {
                    id
                    size
                    price
                    priceHH
                    designation
                }
            }
            establishments {
                id
            }
        }
    }
`;

export const GET_WINE_TYPES = gql`
    query wineTypes($establishments: ID) {
        wineTypes(where: { establishments: $establishments }) {
            id
            name
            wines {
            id
            active
            name
            country
            year
            region
            designation
            producer
            description
            organic
            specs {
                id
                size
                price
                designation
                priceHH
            }
            cards {
                id
            }
            menus {
                id
            }
            }
            establishments {
            id
            }
        }
    }
`;
