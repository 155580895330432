import React, { useContext } from "react";
import "./app.css";

import { EstablishmentContext } from "./Contexts/EstablishmentContext";
import { GeneralContext } from "./Contexts/GeneralContext";

import {
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";

import Splash from "./components/Splash/Splash";
import ButtonList from "./components/ButtonList/ButtonList";
import CardList from "./components/CardList/CardList";
import MenuList from "./components/MenuList/MenuList";

import { GET_ESTABLISHMENT } from "./graphql/gql_Requests";

const App = () => {

    const { general, handleSetGeneral } = useContext(GeneralContext)
    const { establishment, handleSetEstablishment } = useContext(EstablishmentContext)

    const history = useHistory();

    return(
        <div>
            <Switch>
                <Route path="/" exact children={<Splash />} />
                <Route path="/:id" exact children={<ButtonList />} />
                <Route path="/cartes/:id" exact children={<CardList />} />
                <Route path="/menus/:id" exact children={<MenuList />} />
            </Switch>
        </div>
    )

}

export default App;