import React, { useState, useEffect, useContext } from "react"
import { GeneralContext } from "../../Contexts/GeneralContext";
import Flag from "react-world-flags";


import FlagSelect from "../FlagSelect/FlagSelect"

import {
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";

import LogoResto from "./LogoResto";
import "./Header.css"
import photo from  "./visuels/instagram-img3.jpg"


import { PageHeader, Button, Card, Tabs, Input, Empty, Space, Row, Col, notification } from "antd"
import { CheckOutlined, CloseOutlined, FlagFilled, HomeFilled, DeleteFilled } from "@ant-design/icons"


const Header = ({esta}) => {


    const { general, handleSetGeneral, showFlags, flagsVisible } = useContext(GeneralContext);

    
    return (
        <div>
            <FlagSelect />
            <div className="header-content">
                <div className="header-content-buttons">
                    <div className="content"><Link to={`/${esta.id}`}><HomeFilled /></Link></div>
                    <div className="content"><LogoResto /></div>
                    <div className="content"><Flag className="flag" code={general.flag || "fr"} onClick={() => showFlags()} /></div>
                </div>
                {
                esta.logo !== null
                        ? <img src={`https://api.prabel.pro${esta.logo.url}`} alt={esta.Name} />
                    : null
                }
                
                <h1>{esta.Name}</h1>
                <h2>{esta.Description === "* Desc *" ? "Safe Menus" : esta.Name}</h2>
            </div>
        </div>
    )
}

export default Header