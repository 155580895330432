import React, { useState, useEffect, useContext } from "react"
import { EstablishmentContext } from "../../Contexts/EstablishmentContext"
import { GeneralContext } from "../../Contexts/GeneralContext"

import Header from "../Header/Header"
import Footer from "../Footer/Footer"

import { HashLink } from 'react-router-hash-link'

import Content from "./CardContent/Content"

import "./CardList.css"

import {
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";


const CardList = () => {

    // let { id } = useParams();

    const defineCard = (cardID) => {
        handleSetGeneral({ ...general, selectedCard: cardID })
    }

    const { general, handleSetGeneral } = useContext(GeneralContext)
    const { establishment, handleSetEstablishment } = useContext(EstablishmentContext)

    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    useEffect(() => {
        const header = document.getElementById("navbabar");
       if(header) {
           const sticky = header.offsetTop;
           const scrollCallBack = window.addEventListener("scroll", () => {
               if (window.pageYOffset > sticky) {
                   header.classList.add("sticky");
               } else {
                   header.classList.remove("sticky");
               }
           });
           return () => {
               window.removeEventListener("scroll", scrollCallBack);
           };
       }
    }, []);

    const lesCartes = [...establishment.cards].sort(function (a, b) {
        return a.id - b.id;
    });

    return (
        <>
            <Header esta={establishment}/>

            <div className="card-list-content">

                {
                    establishment.cards !== null && establishment.cards.length > 1
                        ? <ul className="menu-horizontal" id="navbabar">
                            {lesCartes.map((card) => (
                                <li key={card.id} className={card.id === general.selectedCard ? 'selected' : null} ><HashLink to={`/cartes/${establishment.id}#${card.id}`} scroll={el => scrollWidthOffset(el)} onClick={() => defineCard(card.id)}>{card.name}</HashLink></li>
                            ))}
                        </ul>
                        : null
                }

                <Content />

                <Footer />

            </div>
        </>
    )
}

export default CardList