import React, { useState, useContext, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GeneralContext } from "../../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../../Contexts/EstablishmentContext";
import { GET_MENUS } from "./gql_Menus";

import "./Content.css"

import Menu from "./Menu"


const Content = () => {

    const [esta, setEsta] = useState('')

    const { general, handleSetGeneral } = useContext(GeneralContext);
    const { establishment } = useContext(EstablishmentContext);

    const [menus, { error: error_gm, data: data_gm }] = useLazyQuery(GET_MENUS, {
        onError: (error_gm) => console.log("error_gm", JSON.stringify(error_gm)),
        onCompleted: (data_gm) => listMenus(data_gm),
        fetchPolicy: "no-cache",
    });

    const [allMenus, setAllmenus] = useState([])
    const [plural, setPlural] = useState(false);

    useEffect(() => {
        // handleSetGeneral({ ...general, page: "/cartes" });

        if (establishment) {
            menus({ variables: { establishment: establishment.id } });
            // setEsta(`- ${establishment.Name} -`)
        }

    }, []);

    const listMenus = (datas) => {
        if (datas.menus.length > 1) {
            setPlural(true);
        }
        const newKidsOnTheBlock = datas.menus.map(c => c)
        newKidsOnTheBlock.sort((a, b) => a.id - b.id)
        setAllmenus(newKidsOnTheBlock)
    }

    if (!establishment) {
        return <p>Erreur... nous ne pouvons pas afficher de formule</p>;
    }

    return (

        <div className="content">
        {
            allMenus.length > 0
                ? allMenus.map(menu => <Menu
                    esta={establishment}
                    menu={menu}
                    listMenus={listMenus}
                />)
                : null
        }
        </div>
        
    )
}

export default Content