import React, { useContext } from "react";
import { SERVER_URL } from "../../../constants";


const Drink = ({ drink }) => {

    return drink.active
        ? (
                  
            <div className="dish-content">

                <div className="gauche">
                    <p className="title">{drink.name}</p>
                    {
                        drink.description !== ""
                            ? <p className="tags"> {drink.description !== null ? `"${drink.description}"` : null}</p>
                            : null
                    }
                        {drink.specs.map((sp, i) => {
                            return (
                                <div key={i} className="specs-loop">
                                    <p className="tags">{sp.designation}: {sp.size ? `${sp.size} cl` : undefined}</p>
                                    <p className="tags">Prix: {sp.price ? `${sp.price} €` : 'non précisé'}</p>
                                    <p className="tags">Prix Happy Hour: {sp.priceHH !== null ? `${sp.priceHH} €` : 'non précisé'}</p>
                                </div>
                            )
                        })}
                </div>

                <div className="droite">
                    <img src="https://api.prabel.pro/uploads/soft_Drinks_Blue_1e66ccfdba.jpg" />
                </div>

            </div>
        )
        : null
}

export default Drink